<template>
  <v-row>
    <v-col
      cols="6"
      xs="6"
      class="text-left"
    >
      <v-btn
        x-small
        elevation="10"
        class="text-xs font-weight-semibold"
        color="info darken-2"
        :to="{ name: 'njangi.contribution_transactions' }"
      >
        <font-awesome-icon
          icon="fa-solid fa-eye"
          class="pe-1"
        />
        View Transactions
      </v-btn>
    </v-col>
    <v-col
      cols="6"
      xs="5"
      class="text-right"
    >
      <v-btn
        x-small
        elevation="10"
        class="text-xs font-weight-semibold"
        color="primary darken-2"
        :to="{ name: 'njangi.make_contribution' }"
      >
        <font-awesome-icon
          icon="fa-solid fa-plus"
          class="pe-1"
        />
        Make a Contribution
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-row class="pt-4 mx-2 pt-0">
          <v-col
            cols="12"
          >
            <v-select
              v-model="selectedRequestFilter"
              ref="selectedRequestFilter"
              outlined
              dense
              :items="requested_types"
              label="Filter Request By"
            ></v-select>
          </v-col>
          <v-col
            cols="5"
            xs="5"
            class="text-left mt-0 pt-0"
          >
            <v-text-field
              v-model="selectedStartDate"
              dense
              type="date"
              label="Start Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="5"
            xs="5"
            class="text-right mt-0 pt-0"
          >
            <v-text-field
              v-model="selectedEndDate"
              dense
              type="date"
              label="End Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="2"
            xs="2"
            class="text-right mt-0 pt-0"
          >
            <spinner v-if="loading" class="text-end"></spinner>
            <v-btn
              v-else
              icon small
              class="primary"
              @click="getRequestedContributions()"
            >
              <font-awesome-icon icon="fa-solid fa-search"/>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-title class="">
          <span class="me-3">({{ requestedContributions.length }}) Requested Contributions</span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="requestedContributions"
          item-key="id"
          class="table-rounded"
          :items-per-page="10"
          disable-sort
          :search="search"
        >
          <!-- name -->
          <template #[`item.username`]="{item}">
            <div class="d-flex flex-column">
              <span class="d-block font-weight-semibold text--primary text-truncate">Contributor: {{ item.username }}</span>
              <small v-if="item.njangi_group_name">{{ item.njangi_group_name[0] }}: {{ item.transaction_operator }} - {{ item.phone_number }} </small>
            </div>
          </template>
          <template #[`item.amount`]="{item}">
            {{ item.amount }}
          </template>

          <!-- status -->
          <template #[`item.status`]="{item}">
            <v-chip 
              :color="statusColor[item.status]"
              @click.stop="setDialog=true"
              @click="changeSwitch(item.id)"
              >
              {{ item.status }}
            </v-chip>
          </template>
          <template #[`item.date_time`]="{item}">
            {{ item.date_time }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-dialog width="500px" v-model="setDialog"> 
        <v-card> 
          <v-card-title class=" white--text">{{ dialogTitle }}</v-card-title> 
          <v-card-text class="black--text pt-2">
            <span v-html="transactionDetails"></span>

            <v-switch label="Validated" color="success" inset v-model="validatedState" @click="changeValidateState()"></v-switch>
            <v-switch label="Cancelled" color="error" inset v-model="cancelledState" @click="changeCancelledState()"></v-switch>
            <v-switch label="Pending" color="primary" inset v-model="pendingState" @click="changePendingState()"></v-switch>
            <v-btn color="primary" @click="changeTransactionState()" > Change </v-btn> 
          </v-card-text> 
          <v-divider></v-divider> 
          <v-card-actions> 
            <v-spacer></v-spacer> 
            <v-btn color="success" text @click="setDialog = false" > Close </v-btn> 
          </v-card-actions> 
        </v-card> 
    </v-dialog> 
  </v-row>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Pending: 'primary',
      Validated: 'success',
      Cancelled: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'Name', value: 'username' },
        { text: 'Amount', value: 'amount' },
        { text: 'Status', value: 'status' },
        { text: 'Date/Time', value: 'date_time' },
      ],
      requestedContributions: [],
      selectedRequestFilter: '',
      requested_types: ['All', 'Pending', 'Validated', 'Cancelled'],
      selectedStartDate: null,
      selectedEndDate: null,
      loading: false,
      statusColor,
      search: '',
      setDialog: false,
      selectedTransaction: null,
      dialogTitle: "Set Status For Transaction",
      transactionDetails: "",
      pendingState: false,
      validatedState: false,
      cancelledState: false,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },

  mounted(){
      // executes these after the page has been mounted
      document.title = "Manager: Njangi | Requested Contributions"
  },

  methods:{
    changeSwitch(transaction_id){
      axios
        .get('/api/v1/manager/njangi/get/requested_transaction/'+transaction_id+'/')
        .then(response => {
          const username = response.data[0]["username"]
          const amount = response.data[0]["amount"]
          this.selectedTransaction = transaction_id
          const njangi_group_name = response.data[0]["njangi_group_name"]

          this.transactionDetails = "Change the state of this requested contribution of amount <b>'"+amount+"'</b> for njangi group <b>'"+njangi_group_name+"'</b> by <b>'"+username+"'</b>"
          console.log(response.data)

          this.pendingState = response.data[0]["pending_state"]
          this.validatedState = response.data[0]["validated_state"]
          this.cancelledState = response.data[0]["cancelled_state"]
        })
        .catch(error => {
          this.setDialog = false
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    changeValidateState(){
      if(this.validatedState){
        this.pendingState = false
        this.cancelledState = false
      }
    },
    
    changeCancelledState(){
      if(this.cancelledState){
        this.validatedState = false
        this.pendingState = false
      }
    },

    changePendingState(){
      if(this.pendingState){
        this.validatedState = false
        this.cancelledState = false
      }
    },

    changeTransactionState(){
      if (!this.pendingState && !this.validatedState && !this.cancelledState){
        this.$store.commit('setSnackBarMessage', "Error: Please select a state")
        this.$store.commit('setSnackBarColor', "red darken-4")
        this.$store.commit('activateSnackBar', true)
        this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
          
      }
      else{
        let formData = new FormData()
        formData.append('pendingState', this.pendingState)
        formData.append('validatedState', this.validatedState)
        formData.append('cancelledState', this.cancelledState)

        axios
          .post('/api/v1/manager/njangi/change/requested_transaction/'+this.selectedTransaction+'/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(response => {
            this.$store.commit('setSnackBarMessage', response.data[0].text)
            this.$store.commit('setSnackBarColor', "primary darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
            this.getRequestedContributions()
            this.setDialog = false
            console.log("Hello man")
          })
          .catch(error => {
            this.setDialog = false
            if (error.response){
              this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
            }else{
              this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
            }
          })
      }
    },

    async getRequestedContributions(){
      if (this.selectedStartDate === ''){
        this.selectedStartDate = 'null'
      }
      if (this.selectedEndDate === ''){
        this.selectedEndDate = 'null'
      }
      if (this.selectedRequestFilter === ''){
        this.$store.commit('setSnackBarMessage', "Please select a category to filter ")
        this.$store.commit('setSnackBarColor', "primary darken-4")
        this.$store.commit('activateSnackBar', true)
        this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
        this.$refs.selectedRequestFilter.focus()  // this causes a an auto fucos to the element
      }
      else{
        this.loading = true
        console.log(this.loading)
        await axios
          .get('/api/v1/manager/njangi/get/requested_transactions/contributions/'+this.selectedRequestFilter+'/'+this.selectedStartDate+'/'+this.selectedEndDate+'/')
          .then(response => {
            this.requestedContributions = response.data  // get the data and fill into campaigns
            console.log(this.requestedContributions)

            if (response.data[0].category === "error"){
              this.$store.commit('setSnackBarMessage', response.data[0].text)
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
            }
          })
          .catch(error => {
            if (error.response){
              this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
            }else{
              this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
            }
          })
        this.loading = false
      }
    },
  },
}
</script>
